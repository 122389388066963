video{
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: fixed;
}

.main-container{
    /*background: url(../../img-css/img-home.jpg) center center/cover  no-repeat;*/
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-justify: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgb(0,0,0,0.2);
    object-fit: contain;
   
  
}
.main-container >h1{
color: #fff;
font-size: 50px;
margin-top: 200px;
}
.main-container >p{
font-size: medium;
color: #fff;
font-size: 32px;
font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.main-btns{
    margin-top: 32px;
   
}

.main-btns .btn{
    margin: 8px;
}

.fa-play-circle{
    margin: 4px;
}

@media screen and (max-width:960px){
.main-container >h1{

font-size: 30px;
margin-top: 100px;
} 
.btn{
    width: 100%;
}
}

@media screen and (max-width:768px){
.main-container >h1{

font-size: 40px;
margin-top: 75px;
} 
.btn{
    width: 100%;
}
}
/*
.btn-mobile{
    display: block;
    text-decoration: none;
}*/