.about-us{
    margin: 20px  200px 0px 200px;
    min-width: 400px;
}

@media  screen and ( max-width:768px) {
    .about-us {
      /*display: flex;*/
      
      margin-left: 5%;
      margin-right: 5%;
      
    }
  }
