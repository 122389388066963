:root{
    --primary:#fff;
}


.btn {
    outline: none;
    border:none;
    padding: 8px 20px;
    border-radius: 2px;
    cursor: pointer;
};
.btn--primary {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: #242424;

}
.btn--outline {
    background-color: transparent;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
    color: #fff;
    padding: 8px,20px;

}

.btn--medium{
font-size: 20px;
padding: 8px 20px;
}
.btn--large{
font-size: 20px;
padding: 12px 26px;
}

btn--medium:hover, .btn--large:hover{
     transition: all 0.3s ease-out;
     color: #fff;
}